.product-card {
  width: 24.375rem;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  container-type: inline-size; }
  @media screen and (min-width: 160.0625rem) {
    .product-card {
      width: 15.23438vw; } }
  @media screen and (max-width: 109.375rem) {
    .product-card {
      width: 18.4375rem; } }
  @media screen and (max-width: 87.5rem) {
    .product-card {
      width: 14.3125rem; } }
  @media screen and (max-width: 39.375rem) {
    .product-card {
      width: 100%; } }
  .product-card:not(.product-card_row) {
    container-name: product-card; }
  .product-card > form {
    position: relative;
    padding: 0.625rem 0.5625rem 1.25rem;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: start;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: start;
    gap: 0.9375rem;
    background-color: var(--bg-white);
    border: solid 1px var(--stroke-dark);
    border-radius: var(--radius-common);
    -webkit-transition: -webkit-box-shadow var(--animation-timing) var(--cubic-bezier);
    transition: -webkit-box-shadow var(--animation-timing) var(--cubic-bezier);
    -o-transition: box-shadow var(--animation-timing) var(--cubic-bezier);
    transition: box-shadow var(--animation-timing) var(--cubic-bezier);
    transition: box-shadow var(--animation-timing) var(--cubic-bezier), -webkit-box-shadow var(--animation-timing) var(--cubic-bezier); }
    @media screen and (min-width: 160.0625rem) {
      .product-card > form {
        padding: 0.39062vw 0.35156vw 0.78125vw;
        gap: 0.58594vw;
        border-width: 0.03906vw; } }
    @media screen and (max-width: 109.375rem) {
      .product-card > form {
        padding: 0.625rem 0.5625rem 0.9375rem;
        gap: 0.625rem; } }
    @media (any-hover: hover) {
      .product-card > form:hover {
        -webkit-box-shadow: var(--shadow-large);
                box-shadow: var(--shadow-large); }
        .product-card > form:hover .product-card__image img {
          -webkit-transform: scale(1.1);
              -ms-transform: scale(1.1);
                  transform: scale(1.1); } }
  .product-card__top {
    position: relative;
    padding: 0.3125rem;
    width: 100%;
    height: 15.625rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    gap: 4.3125rem;
    border-radius: var(--radius-common);
    overflow: hidden; }
    @media screen and (min-width: 160.0625rem) {
      .product-card__top {
        padding: 0.19531vw;
        height: 9.76562vw;
        gap: 2.69531vw; } }
    @media screen and (max-width: 109.375rem) {
      .product-card__top {
        height: 11.625rem;
        gap: 3.6875rem; } }
    @media screen and (max-width: 87.5rem) {
      .product-card__top {
        height: 8.75rem; } }
    @media screen and (max-width: 39.375rem) {
      .product-card__top {
        height: 11.25rem; } }
  .product-card__image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    padding: 0.625rem;
    width: 100%;
    height: 100%;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; }
    .product-card__image img {
      width: 100%;
      height: 100%;
      -o-object-fit: contain;
         object-fit: contain;
      -o-object-position: center;
         object-position: center;
      pointer-events: none;
      -webkit-transition: -webkit-transform var(--animation-timing-medium) var(--cubic-bezier);
      transition: -webkit-transform var(--animation-timing-medium) var(--cubic-bezier);
      -o-transition: transform var(--animation-timing-medium) var(--cubic-bezier);
      transition: transform var(--animation-timing-medium) var(--cubic-bezier);
      transition: transform var(--animation-timing-medium) var(--cubic-bezier), -webkit-transform var(--animation-timing-medium) var(--cubic-bezier); }
  .product-card__tag-list {
    position: relative;
    z-index: 1;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: start;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: start;
    place-content: start;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 0.3125rem; }
    @media screen and (min-width: 160.0625rem) {
      .product-card__tag-list {
        gap: 0.19531vw; } }
    .product-card__tag-list_top-right {
      position: absolute;
      top: 0.625rem;
      right: 0.625rem; }
    .product-card__tag-list_bottom-right {
      position: absolute;
      bottom: 0.625rem;
      right: 0.625rem; }
  .product-card__menu {
    position: relative;
    z-index: 1;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: end;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: start;
    place-content: start;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 0.3125rem; }
    @media screen and (min-width: 160.0625rem) {
      .product-card__menu {
        gap: 0.19531vw; } }
    @media screen and (max-width: 109.375rem) {
      .product-card__menu .button {
        padding: 0.375rem; } }
  .product-card__wishlist_active .button__icon, .product-card__wishlist.comparison-remove .button__icon, .product-card__comparison_active .button__icon, .product-card__comparison.comparison-remove .button__icon {
    fill: var(--accent-color); }
  @media (any-hover: hover) {
    .product-card__wishlist_active:hover .button__icon, .product-card__wishlist.comparison-remove:hover .button__icon, .product-card__comparison_active:hover .button__icon, .product-card__comparison.comparison-remove:hover .button__icon {
      fill: var(--elements-dark); } }
  @media (any-hover: hover) {
    .product-card__wishlist_active.animation:hover .button__icon, .product-card__wishlist.comparison-remove.animation:hover .button__icon, .product-card__comparison_active.animation:hover .button__icon, .product-card__comparison.comparison-remove.animation:hover .button__icon {
      fill: var(--elements-white); } }
  .product-card__bottom {
    padding: 0 0.625rem;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: start;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: start;
    gap: 0.9375rem; }
    @media screen and (min-width: 160.0625rem) {
      .product-card__bottom {
        padding: 0 0.39062vw;
        gap: 0.58594vw; } }
    @media screen and (max-width: 109.375rem) {
      .product-card__bottom {
        padding: 0 0.3125rem;
        gap: 0.625rem; } }
  .product-card__article {
    color: var(--text-dark-tertiary); }
  .product-card__info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: start;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: start;
    gap: 0.4375rem;
    width: 100%; }
    @media screen and (min-width: 160.0625rem) {
      .product-card__info {
        gap: 0.27344vw; } }
    @media screen and (max-width: 109.375rem) {
      .product-card__info {
        gap: 0.25rem; } }
  .product-card__info-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    gap: 0.625rem;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    width: 100%; }
  .product-card__name {
    display: -webkit-box;
    height: 3rem;
    color: var(--text-dark-primary);
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical; }
    @media screen and (min-width: 160.0625rem) {
      .product-card__name {
        height: 1.875vw; } }
    @media screen and (max-width: 109.375rem) {
      .product-card__name {
        height: 2.625rem; } }
    @media screen and (max-width: 87.5rem) {
      .product-card__name {
        height: 3.9375rem;
        -webkit-line-clamp: 3; } }
    @media screen and (max-width: 39.375rem) {
      .product-card__name {
        height: 2.625rem;
        -webkit-line-clamp: 2; } }
    @media (any-hover: hover) {
      .product-card__name:hover {
        color: var(--text-dark-primary); } }
    .product-card__name::before {
      content: "";
      position: absolute;
      top: -0rem;
      left: -0rem;
      width: calc(100% + 0rem * 2);
      height: calc(100% + 0rem * 2);
      background-color: transparent;
      opacity: 0; }
      @media screen and (min-width: 160.0625rem) {
        .product-card__name::before {
          top: 0vw;
          left: 0vw;
          width: calc(100% + 0vw * 2);
          height: calc(100% + 0vw * 2); } }
  .product-card__button {
    margin-top: auto;
    padding: 0 0.625rem;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    gap: 0.625rem; }
    @media screen and (min-width: 160.0625rem) {
      .product-card__button {
        padding: 0 0.39062vw;
        gap: 0.39062vw; } }
    @supports (container-type: inline-size) {
      .product-card__button {
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap; }
      @container product-card (max-width: 18.125rem) {
        .product-card__button {
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; } } }
    @media screen and (max-width: 109.375rem) {
      .product-card__button {
        padding: 0 0.3125rem; } }
  @media screen and (max-width: 109.375rem) and (min-width: 160.0625rem) {
    .product-card__button {
      padding: 0 0.19531vw; } }
    .product-card__button > .button,
    .product-card__button > .number-input {
      width: 100%;
      -webkit-box-flex: 1;
          -ms-flex-positive: 1;
              flex-grow: 1;
      white-space: nowrap; }
  @media screen and (max-width: 87.5rem) {
    .product-card__cart {
      padding: 0.75rem; } }
  .product-card_row {
    width: 100%;
    min-height: 19rem;
    height: auto;
    container-name: product-card_row; }
    @media screen and (min-width: 160.0625rem) {
      .product-card_row {
        min-height: 11.875vw; } }
    @media screen and (max-width: 109.375rem) {
      .product-card_row {
        min-height: 16.875rem; } }
    @media screen and (max-width: 39.375rem) {
      .product-card_row {
        display: none; } }
    .product-card_row > form {
      padding: 0.625rem;
      display: grid;
      grid-template-columns: 23.125rem 1fr;
      gap: 0.625rem; }
      @media screen and (min-width: 160.0625rem) {
        .product-card_row > form {
          padding: 0.39062vw;
          grid-template-columns: 14.45312vw 1fr; } }
      @media screen and (max-width: 109.375rem) {
        .product-card_row > form {
          grid-template-columns: 17.1875rem 1fr; } }
      @media screen and (max-width: 87.5rem) {
        .product-card_row > form {
          grid-template-columns: 13.0625rem 1fr; } }
    .product-card_row .product-card__top {
      height: 100%; }
    .product-card_row .product-card__bottom {
      padding: 0.625rem;
      height: 100%;
      gap: 0.9375rem; }
      @media screen and (min-width: 160.0625rem) {
        .product-card_row .product-card__bottom {
          padding: 0.39062vw;
          gap: 0.58594vw; } }
      @media screen and (max-width: 109.375rem) {
        .product-card_row .product-card__bottom {
          padding: 0.3125rem;
          gap: 0.625rem; } }
    .product-card_row .product-card__info {
      width: 100%;
      gap: 0.625rem; }
      @media screen and (min-width: 160.0625rem) {
        .product-card_row .product-card__info {
          gap: 0.39062vw; } }
      @media screen and (max-width: 109.375rem) {
        .product-card_row .product-card__info {
          gap: 0.5rem; } }
    .product-card_row .product-card__flex {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      gap: 2rem;
      margin-top: auto; }
      @media screen and (min-width: 160.0625rem) {
        .product-card_row .product-card__flex {
          gap: 1.25vw; } }
      @supports (container-type: inline-size) {
        @container product-card_row (max-width: 670px) {
          .product-card_row .product-card__flex {
            -webkit-box-align: end;
                -ms-flex-align: end;
                    align-items: end; } } }
      .product-card_row .product-card__flex .price {
        gap: 0.625rem; }
        @media screen and (min-width: 160.0625rem) {
          .product-card_row .product-card__flex .price {
            gap: 0.39062vw; } }
        @media screen and (max-width: 760px) {
          .product-card_row .product-card__flex .price {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
            gap: 0.125rem; } }
        .product-card_row .product-card__flex .price__old {
          font-size: 0.875rem;
          line-height: 1.5; }
          @media screen and (min-width: 160.0625rem) {
            .product-card_row .product-card__flex .price__old {
              font-size: 0.54688vw; } }
          @media screen and (max-width: 760px) {
            .product-card_row .product-card__flex .price__old {
              -webkit-box-ordinal-group: 2;
                  -ms-flex-order: 1;
                      order: 1; } }
        .product-card_row .product-card__flex .price__sum {
          font-size: 1.5rem;
          line-height: 1.1; }
          @media screen and (min-width: 160.0625rem) {
            .product-card_row .product-card__flex .price__sum {
              font-size: 0.9375vw; } }
          @media screen and (max-width: 109.375rem) {
            .product-card_row .product-card__flex .price__sum {
              font-size: 1.375rem; } }
    .product-card_row .product-card__name {
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content; }
    .product-card_row .product-card__more {
      display: none; }
      @media screen and (max-width: 109.375rem) {
        .product-card_row .product-card__more {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex; } }
    .product-card_row .product-card__characteristics {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      gap: 0.375rem; }
      @media screen and (min-width: 160.0625rem) {
        .product-card_row .product-card__characteristics {
          gap: 0.23438vw; } }
      @media screen and (max-width: 109.375rem) {
        .product-card_row .product-card__characteristics {
          margin-top: -0.125rem; } }
      @media screen and (max-width: 87.5rem) {
        .product-card_row .product-card__characteristics {
          margin-top: 0; } }
      .product-card_row .product-card__characteristics table {
        padding: 0;
        margin: 0;
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column; }
      .product-card_row .product-card__characteristics tbody {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column; }
      .product-card_row .product-card__characteristics tr {
        padding: 0.40625rem 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        gap: 0.625rem;
        color: var(--text-dark-secondary);
        border-bottom: dashed 1px var(--stroke-dark); }
        @media screen and (min-width: 160.0625rem) {
          .product-card_row .product-card__characteristics tr {
            padding: 0.25391vw 0;
            gap: 0.39062vw;
            border-bottom-width: 0.03906vw; } }
        @media screen and (max-width: 109.375rem) {
          .product-card_row .product-card__characteristics tr {
            padding: 0.28125rem 0;
            font-size: 0.875rem; } }
        .product-card_row .product-card__characteristics tr:first-child {
          padding-top: 0; }
        .product-card_row .product-card__characteristics tr:last-child {
          padding-bottom: 0;
          border-bottom: none; }
          @media screen and (max-width: 109.375rem) {
            .product-card_row .product-card__characteristics tr:last-child {
              padding-bottom: 0.28125rem; } }
      .product-card_row .product-card__characteristics td:last-child {
        color: var(--text-dark-primary); }
    .product-card_row .product-card__button {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-pack: end;
          -ms-flex-pack: end;
              justify-content: end;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      padding: 0; }
      @supports (container-type: inline-size) {
        @container product-card_row (max-width: 768px) {
          .product-card_row .product-card__button .button__content svg {
            display: none; }
            @media screen and (max-width: 87.5rem) {
              .product-card_row .product-card__button .button__content svg {
                display: block; } }
          @media screen and (max-width: 87.5rem) {
            .product-card_row .product-card__button .button__content span {
              display: none; } } } }
      .product-card_row .product-card__button > .button,
      .product-card_row .product-card__button > .number-input {
        width: unset; }
        .product-card_row .product-card__button > .button:nth-child(2),
        .product-card_row .product-card__button > .number-input:nth-child(2) {
          width: unset; }
  .product-card_small .product-card__button {
    padding: 0; }
