@import '../../mixins';

.product-card {
  width: rem(390);
  height: 100%;
  display: flex;

  container-type: inline-size;

  @include mediaBigDesktop {
    width: big(390);
  }

  @include mediaLaptop {
    width: rem(295);
  }

  @include mediaTablet {
    width: rem(229);
  }

  @include mediaMobile {
    width: 100%;
  }

  &:not(.product-card_row) {
    container-name: product-card;
  }

  & > form {
    position: relative;
    padding: rem(10) rem(9) rem(20);

    width: 100%;
    //height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: rem(15);

    background-color: var(--bg-white);
    border: solid 1px var(--stroke-dark);
    border-radius: var(--radius-common);

    transition: box-shadow var(--animation-timing) var(--cubic-bezier);

    @include mediaBigDesktop {
      padding: big(10) big(9) big(20);

      gap: big(15);

      border-width: big(1);
    }

    @include mediaLaptop {
      padding: rem(10) rem(9) rem(15);
      gap: rem(10);
    }

    @include hover {
      box-shadow: var(--shadow-large);

      & .product-card {
        &__image {
          & img {
            transform: scale(1.1);
          }
        }
      }
    }
  }

  &__top {
    position: relative;
    padding: rem(5);

    width: 100%;
    height: rem(250);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: rem(69);

    border-radius: var(--radius-common);
    overflow: hidden;

    @include mediaBigDesktop {
      padding: big(5);

      height: big(250);
      gap: big(69);
    }

    @include mediaLaptop {
      height: rem(186);
      gap: rem(59);
    }

    @include mediaTablet {
      height: rem(140);
    }

    @include mediaMobile {
      height: rem(180);
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    
    padding: rem(10);
    
    width: 100%;
    height: 100%;
    user-select: none;

    & img {
      width: 100%;
      height: 100%;

      object-fit: contain;
      object-position: center;

      pointer-events: none;

      transition: transform var(--animation-timing-medium) var(--cubic-bezier);
    }
  }

  &__tag-list {
    position: relative;
    z-index: 1;

    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;
    place-content: start;
    flex-wrap: wrap;
    gap: rem(5);

    @include mediaBigDesktop {
      gap: big(5);
    }
    
    &_top-right {
      position: absolute;
      top: rem(10);
      right: rem(10);
    }
    
    &_bottom-right {
      position: absolute;
      bottom: rem(10);
      right: rem(10);
    }
  }

  &__menu {
    position: relative;
    z-index: 1;

    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: start;
    place-content: start;
    flex-wrap: wrap;
    gap: rem(5);

    @include mediaBigDesktop {
      gap: big(5);
    }

    & .button {
      @include mediaLaptop {
        padding: rem(6);
      }
    }
  }

  &__wishlist,
  &__comparison {

    &_active,
    &.comparison-remove {
      & .button {
        &__icon {
          fill: var(--accent-color);
        }
      }

      @include hover {
        & .button {
          &__icon {
            fill: var(--elements-dark);
          }
        }
      }

      &.animation {

        @include hover {
          & .button {
            &__icon {
              fill: var(--elements-white);
            }
          }
        }
      }
    }
  }

  &__bottom {
    padding: 0 rem(10);

    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: rem(15);

    @include mediaBigDesktop {
      padding: 0 big(10);
      gap: big(15);
    }

    @include mediaLaptop {
      padding: 0 rem(5);

      gap: rem(10);
    }
  }
  
  &__article {
    color: var(--text-dark-tertiary);
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    gap: rem(7);
    width: 100%;

    @include mediaBigDesktop {
      gap: big(7);
    }

    @include mediaLaptop {
      gap: rem(4);
    }
  }
  
  &__info-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: rem(10);
    flex-wrap: wrap;
    width: 100%;
  }

  &__name {
    display: -webkit-box;
    height: rem(48);

    color: var(--text-dark-primary);

    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    @include mediaBigDesktop {
      height: big(48);
    }

    @include mediaLaptop {
      height: rem(42);
    }

    @include mediaTablet {
      height: rem(63);

      -webkit-line-clamp: 3;
    }

    @include mediaMobile {
      height: rem(42);

      -webkit-line-clamp: 2;
    }

    @include hover {
      color: var(--text-dark-primary);
    }

    &::before {
      @include pseudo(0)
    }
  }

  &__button {
    margin-top: auto;
    padding: 0 rem(10);
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: rem(10);

    @include mediaBigDesktop {
      padding: 0 big(10);
      gap: big(10);
    }

    @supports (container-type: inline-size) {
      flex-wrap: nowrap;

      @container product-card (max-width: #{rem(290)}) {
        flex-wrap: wrap;
      }
    }

    @include mediaLaptop {
      padding: 0 rem(5);

      @include mediaBigDesktop {
        padding: 0 big(5);
      }
    }

    & > .button,
    & > .number-input {
      width: 100%;
      flex-grow: 1;

      white-space: nowrap;

      //& .button {
      //&__content {
      //& svg {
      //display: none;

      //@include mediaTablet {
      //display: block;
      //}
      //}

      //& span {
      //@include mediaTablet {
      //display: none;
      //}
      //}
      //}
      //}
    }
  }

  &__cart {
    @include mediaTablet {
      padding: rem(12);
    }
  }

  &_row {
    width: 100%;
    min-height: rem(304);
    height: auto;

    container-name: product-card_row;

    @include mediaBigDesktop {
      min-height: big(304);
    }

    @include mediaLaptop {
      min-height: rem(270);
    }

    @include mediaMobile {
      display: none;
    }

    & > form {
      padding: rem(10);

      display: grid;
      grid-template-columns: rem(370) 1fr;
      gap: rem(10);

      @include mediaBigDesktop {
        padding: big(10);

        grid-template-columns: big(370) 1fr;
      }

      @include mediaLaptop {
        grid-template-columns: rem(275) 1fr;
      }

      @include mediaTablet {
        grid-template-columns: rem(209) 1fr;
      }
    }

    & .product-card {
      &__top {
        height: 100%;
      }

      &__bottom {
        padding: rem(10);
        height: 100%;

        gap: rem(15);

        @include mediaBigDesktop {
          padding: big(10);
          gap: big(15);
        }

        @include mediaLaptop {
          padding: rem(5);

          gap: rem(10);
        }
      }

      &__info {
        width: 100%;
        gap: rem(10);

        @include mediaBigDesktop {
          gap: big(10);
        }

        @include mediaLaptop {
          gap: rem(8);
        }
      }

      &__flex {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: rem(32);
        margin-top: auto;

        @include mediaBigDesktop {
          gap: big(32);
        }

        @supports (container-type: inline-size) {

          @container product-card_row (max-width: 670px) {
            align-items: end;
          }
        }

        & .price {
          gap: rem(10);

          @include mediaBigDesktop {
            gap: big(10);
          }

          @include media(760px) {
            flex-direction: column;
            gap: rem(2);
          }

          &__old {
            font-size: rem(14);
            line-height: 1.5;

            @include mediaBigDesktop {
              font-size: big(14);
            }

            @include media(760px) {
              order: 1;
            }
          }

          &__sum {
            font-size: rem(24);
            line-height: 1.1;

            @include mediaBigDesktop {
              font-size: big(24);
            }

            @include mediaLaptop {
              font-size: rem(22);
            }
          }
        }
      }

      &__name {
        height: fit-content;
      }

      &__more {
        display: none;

        @include mediaLaptop {
          display: flex;
        }
      }

      &__characteristics {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: rem(6);

        @include mediaBigDesktop {
          gap: big(6);
        }

        @include mediaLaptop {
          margin-top: rem(-2);
        }

        @include mediaTablet {
          margin-top: 0;
        }

        & table {
          padding: 0;
          margin: 0;

          width: 100%;
          display: flex;
          flex-direction: column;
        }

        & tbody {
          width: 100%;
          display: flex;
          flex-direction: column;
        }

        & tr {
          padding: rem(6.5) 0;

          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          gap: rem(10);

          color: var(--text-dark-secondary);

          border-bottom: dashed 1px var(--stroke-dark);

          @include mediaBigDesktop {
            padding: big(6.5) 0;

            gap: big(10);

            border-bottom-width: big(1);
          }

          @include mediaLaptop {
            padding: rem(4.5) 0;

            font-size: rem(14);
          }

          &:first-child {
            padding-top: 0;
          }

          &:last-child {
            padding-bottom: 0;

            border-bottom: none;

            @include mediaLaptop {
              padding-bottom: rem(4.5);
            }
          }
        }

        & td {
          &:last-child {
            color: var(--text-dark-primary);
          }
        }
      }

      &__button {
        display: flex;
        flex-direction: row;
        justify-content: end;
        width: fit-content;
        padding: 0;

        @supports (container-type: inline-size) {

          @container product-card_row (max-width: 768px) {
            .button {
              &__content {
                & svg {
                  display: none;

                  @include mediaTablet {
                    display: block;
                  }
                }

                & span {
                  @include mediaTablet {
                    display: none;
                  }
                }
              }
            }
          }
        }

        & > .button,
        & > .number-input {
          width: unset;

          &:nth-child(2) {
            width: unset;
          }
        }
      }
    }
  }

  &_small {
    & .product-card {
      &__button {
        padding: 0;
      }
    }
  }
}
